@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;800&family=Oxygen:wght@300;400;700&display=swap');

:root {
    --almost-black: #000000;
    --deep-forest-green: #292b2b;
    --light-beige: #f4efeb;
    --beige: #d2c0b2;
    --clay: #9d6556;
  }

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Nunito', sans-serif;
    font-family: 'Oxygen', sans-serif;
}

ul {
    list-style-type: none;
}

a {
    text-decoration: none;
}

.btn {
    width: 215px;
    height: 50px;
    background-color: var(--clay);
    color: #fff;
    font-size: 1.1rem;
    text-transform: uppercase;
    cursor: pointer;
    border: 2px solid transparent;
    transition: 250ms linear;
    border-radius: 5px;
}

.btn:hover {
    background-color: transparent;
    border: 2px solid var(--clay);
    color: #000;
}

/* Animation */
/* @keyframes fadeInSlideUp {
    0% {
        opacity: 0;
        transform: translateY(50px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
} */