.image-01 {
    flex: 1;  
  }

.image-02 {
    flex: 2;  
}
  
.image-03 {
    flex: 3;  
}

.image-popup {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    z-index: 1000;
}

.image-popup .image-popup-cont {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
}

.image-popup .image-popup-cont p {
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold;
    transform: translateY(35px);
    margin-right: 10px;
}

.image-popup .image-popup-cont img {
    width: auto;
    max-width: 100%;
    height: 80vh;
    object-fit: cover;
    border-radius: 5px;
}