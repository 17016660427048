.hospice-services{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 6rem 10px;
    gap: 8rem;
}

@media (max-width: 680px) {
    .hospice-services{
        flex-direction: column;
    }
}

.hospice-services .hospice-services-left {
    position: sticky;
    top: 25%;
}

@media (max-width: 1092px) {
    .hospice-services .hospice-services-left {
        top: 15%;
    }
}

@media (max-width: 680px) {
    .hospice-services .hospice-services-left {
        width: 100%;
        position:static;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.hospice-services .hospice-services-left h2 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: center;
    color: var(--clay);
}

.hospice-services .hospice-services-left p {
    font-size: 1.6rem;
    font-weight: normal;
    margin-bottom: 2rem;
    text-align: center;
}

.hospice-services .hospice-services-left img {
    max-width: 100%;
    width: auto;
    object-fit: cover;
    height: 400px;
    border-radius: 10px;
}

.hospice-services .hospice-services-right .hospice-services-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    border-top: 1px solid var(--beige);
    border-bottom: 1px solid var(--beige);
    padding: 3rem 4rem 3rem 0rem;
}

.hospice-services .hospice-services-right .hospice-services-box p {
    font-size: 1.8rem;
}

@media (max-width: 680px) {
    .hospice-services .hospice-services-right .hospice-services-box p {
        font-size: 1.3rem;
    }
}

.hospice-services .hospice-services-right .hospice-services-box p>svg {
    color: var(--clay);
    font-size: 2.5rem;
}