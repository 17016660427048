.footer {
    width: 100%;
    background: var(--deep-forest-green);
}

.footer-container {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 5rem;
    color: #fff;
    padding: 2rem 0;
}

.footer .right {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.footer .left {
    width: 100%;
    max-width: 650px;
    height: 100%;
    display: flex;           
    flex-direction: column;
    padding: 1.2rem;
}

.footer .footer-container .left .social a {
    color: #fff;
    transition: 250ms linear;
}

.footer .footer-container .left .social a:hover {
    color: rgba(255, 255, 255, .7);
}

.footer h4 {
    font-size: 1.4rem;
    padding: 1rem 0;
}

.footer p {
    font-size: 1.2rem;
    padding: 1rem 0;
    text-align: justify;
}

.footer .location {
    display: flex;
    align-items: center;
}

.footer p {
    padding-bottom: .5rem;
}

.footer .location h4 {
    padding-top: 0%;
}

.footer .phone {
    display: flex;
    align-items: center;
}

.footer .mail {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 640px) {
    .footer-container {
        grid-template-columns: 1fr;
    }

    .right .social {
        margin: auto;
    }
}

.footer-down {
    width: 100%;
    height: 50px;
    background-color: var(--almost-black);
    overflow: hidden;
}

.footer-down p {
    color: #fff;
    text-align: center;
}

.footer-down p span a {
    color: var(--beige);
    transition: 150ms ease-in-out;
}

.footer-down p span a:hover {
    color: var(--clay);
}