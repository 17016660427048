.gallery {
    width: 100%;
    height: auto;
    overflow: hidden;
}

.image-grid {
    padding: 4rem 12px;
}

.image-row {
    display: flex;
}

.image-row .image {
    margin: 12px;
    width: 100%;
    height: 350px;  
    object-fit: cover;
    border-radius: 5px;
    cursor: pointer;
}

.image { 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 800px) {
    .image-grid {
        padding: 4rem 12px 4rem 0;
    }

    .image-row {
        flex-direction: column;
        padding: 0 14px 0 0;
    }

    .image-row .image {
        flex-basis: auto;
    }
}