.accommodation {
    width: 100%;
    height: auto;
    padding: 4rem 0 0 0;
}

.accommodation-intro {
    width: 100%;
    min-height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.accommodation .accommodation-intro h2 {
    font-weight: normal;
    font-size: 2rem;
    text-align: center;
    padding: 2rem 1rem 4rem 1rem;
}

.accommodation .room-wrapper {
    width: 100%;
}