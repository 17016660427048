.contact {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 8rem 10px;
    gap: 12rem;
    flex-wrap: wrap;
}

@media (max-width: 1564px) {
    .contact {
        gap: 4rem;
    }
}

.contact .contact-cont h4 {
    font-size: 3rem;
    margin-bottom: 2rem;
    color: var(--clay);
}

.contact .contact-cont .icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    font-size: 1.5rem;
    margin: 1.5rem 0;
}

.contact .contact-cont svg {
    background-color: var(--light-beige);
    border-radius: 10px;
    width: 50px;
    height: 50px;
    padding: 10px;
}

.contact .contact-form p {
    max-width: 600px;
    font-size: 1.3rem;
}

.contact .contact-form h4 {
    font-size: 2rem;
    margin: 2rem 0 1rem 0;
    color: var(--clay);
}

.contact .contact-form form {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.contact .contact-form form label {
    font-size: 1.4rem;
    margin-top: .5rem 0;
}

.contact .contact-form form input {
    padding: 5px;
    font-size: 1.2rem;
    margin: .5rem 0;
    width: 100%;
    outline: none;
    border: none;
    background-color: var(--light-beige);
    border: 1px solid var(--beige);
}

.contact .contact-form form textarea {
    padding: 5px;
    font-size: 1.2rem;
    margin: 1rem 0;
    width: 100%;
    outline: none;
    border: none;
    background-color: var(--light-beige);
    border: 1px solid var(--beige);
}