.countline {
    width: 100%;
    height: auto;
    background-color: #d2c0b2;
    min-height: 400px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;
    padding: 2rem 1rem;
} 

@media (max-width: 916px) {
    .countline {
        padding: 4rem 1rem;
    } 
}

.countline .countline-left h2 {
    font-size: 3.5rem;
    font-weight: 600;
}

@media (max-width: 916px) {
    .countline .countline-left h2 {
        text-align: center;
        font-size: 2.5rem;
    }
}

.countline .countline-right {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8rem;
}

@media (max-width: 400px) {
    .countline .countline-right {
        flex-direction: column;
        gap: 2rem;
    }
}

.countline .countline-right .countline-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
}

.countline .countline-right .countline-box {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: wrap;
}

@media (max-width: 400px) {
    .countline .countline-right .countline-box {
        justify-content: center;
        align-items: center;
    }

    .countline .countline-right .countline-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
    }    
}

.countline .countline-right .countline-box p {
    font-size: 6rem;
    font-weight: normal;
    color: var(--clay);
    font-weight: 600;
}

.countline .countline-right .countline-box span {
    font-size: 1.5rem;
    font-weight: normal;
}