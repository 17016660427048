.home-about {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    background-color:#fff;
    padding-bottom: 4rem;
}

.home-about .home-about-cont {
    padding: 0 1rem;
    width: 100%;
    max-width: 800px;
}

.home-about .home-about-cont h2 {
    font-size: 3rem;    
    color: #9d6556;
    font-weight: 600;
}

.home-about .home-about-cont p {
    font-size: 1.4rem;
    margin: 2rem 0 5rem 0;
}

@media (max-width: 1823px) {
    .home-about .home-about-cont p {
        max-width: 1200px;
        width: 100%;
        font-size: 1.4rem;
        text-align: center;
        margin: 2rem auto 5rem auto;
    }
}

.home-about .home-about-cont button {
    padding: 12px 36px;
}

.home-about .home-about-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1823px) {
    .home-about .home-about-right {
        flex-direction: row;
        column-gap: 4rem;
        margin-top: 8rem;
    }
}

@media (max-width: 1487px) {
    .home-about .home-about-right {
        margin-top: 2rem;
        flex-direction: column;
    }
}

.home-about .home-about-right div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000;
    transition: 250ms ease-in-out;
}

/* .home-about .home-about-right div:hover {
    transform: scale(1.05);
} */

.home-about .home-about-right div:nth-child(2) {
    margin: 2rem 0;
    transform: translateX(-150px);
}

.home-about .home-about-right div:nth-child(2) a {
    color: #000;
    text-align: center;
}

@media (max-width: 1823px) {
    .home-about .home-about-right div:nth-child(2) {
        margin: 4rem 0;
        transform: translateY(-150px);
    }
}

@media (max-width: 1487px) {
    .home-about .home-about-right div:nth-child(2) {
        transform: translateY(0px);
    }
}

.home-about .home-about-right div img {
    width: 375px;
    height: 250px;
    border-radius: 50px;
}

.home-about .home-about-right div h4 {
    font-size: 1.5rem;
    font-weight: normal;
    margin-top: 1rem;
}

@media (max-width:1823px) {
    .home-about {
        gap: 4rem;
        padding: 4rem 0rem;
    }

    .home-about .home-about-cont {
       max-width: 100%;
       text-align: center;
    }
    
    .home-about-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .home-about-img img {
        margin: 0 auto;
    }

}

@media (max-width: 600px) {
    .home-about .home-about-cont h2 {
        font-size: 2rem;    
    }
    
    .home-about .home-about-cont p {
        font-size: 1.1rem;
    }
}


