.main-image {
    width: 100%;
    height: 60vh;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(../assets/hero.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    clip-path: polygon(100% 0, 100% 100%, 74% 94%, 45% 100%, 18% 94%, 0 100%, 0 0);
    overflow: hidden;
}

@media (max-width: 700px) {
    .main-image {
        background-attachment: unset;
    }
}

.main-image .main-image-cont {
    width: 100%;
    max-width: 1280px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 10px;
    text-align: center;
    margin: 0 auto;
    overflow: hidden;
}

.main-image .main-image-cont h2 {
    color: #fff;
    font-size: 4rem;
    border-bottom: 3px solid #fff;
    padding-bottom: 1rem;
    margin-bottom: 4rem;
}

@media (max-width: 420px) {
    .main-image .main-image-cont h2 {
        font-size: 3rem;
    }
}

@media (max-width: 310px) {
    .main-image .main-image-cont h2 {
        font-size: 2.5rem;
    }
}