.hero {
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('../../assets/hero.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: polygon(100% 0, 100% 100%, 81% 93%, 54% 100%, 23% 96%, 0 100%, 0 0);
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;
}

.hero video {
    position: absolute;
    top: 0;
    filter: brightness(85%);
}

@media (max-width: 700px) {
    .hero {
        background-attachment: unset;
    }
}

.hero .hero-cont {
    width: 100%;
    max-width: 1280px;
    height: 100vh;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.hero .hero-cont h2 {
    font-size: 6rem;
    margin-bottom: 2rem;
    color: #fff;
    /* animation: 1s fadeInSlideUp ease-in-out; */
}

.hero .hero-cont p {
    font-size: 2.5rem;
    color: #fff;
    /* animation: 1s fadeInSlideUp ease-in-out; */
}

.hero .hero-cont a .btn {
    margin: 2rem 0;
    /* animation: 1s fadeInSlideUp ease-in-out; */
}

.hero .hero-cont a .btn:hover {
    color: #fff !important;
    background-color: var(--clay);
    transform: translateY(-5px);
}

@media (max-width: 470px) {
    .hero .hero-cont h2 {
        font-size: 3rem;
    }

    .hero .hero-cont p {
        font-size: 1.5rem;
    }
}