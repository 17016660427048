.room {
    width: 100%;
    height: auto;
    background-color: #ececec;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 12rem;
    padding: 8rem 10px;
    overflow: hidden;
}

@media (max-width: 1277px) {
    .room {
        gap: 4rem;
    }
}

.room-bg {
    background-color: var(--beige);
    clip-path: polygon(0 10%, 50% 0, 100% 10%, 100% 90%, 50% 100%, 0 90%);
    padding: 12rem 10px !important;
}

@media (max-width: 1187px) {
    .room-bg {
        flex-direction: column-reverse;
        padding: 12rem 0;
    }
}

.room .room-img img {
    max-width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
}

.room .room-cont h4 {
    font-size: 4rem;
    font-weight: 600;
    color: var(--clay);
}

@media (max-width: 420px) {
    .room .room-cont h4 {
        font-size: 3rem;
    }
}

.room .room-cont p {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 1rem 0 2rem 0;
}

.room .room-cont ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.room .room-cont ul li {
    font-size: 1.2rem;
    font-weight: 400;
}

/* Swiper */
.room-swiper {
    width: 100%;
    max-width: 600px;
}

@media (max-width: 620px) {
    .room-swiper {
        max-width: 500px;
    }
}

@media (max-width: 520px) {
    .room-swiper {
        max-width: 400px;
    }
}

@media (max-width: 420px) {
    .room-swiper {
        max-width: 300px;
    }
}

@media (max-width: 320px) {
    .room-swiper {
        max-width: 250px;
    }
}

.room-swiper .swiper-button-next {
    color: #fff;
}

.room-swiper .swiper-button-prev {
    color: #fff;
}

.room-swiper .swiper-pagination span {
    background-color: #fff;
}

.room-swiper img {
    width: 100%;
    max-width: 600px;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
}